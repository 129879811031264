import React from "react";
import Hero from "./Hero";



function Home () {

    return (
    
        
         <Hero/>

           

           

    )
}


export default Home
import * as React from "react"


const Svg1 = (props) => (
  <svg  
  className="svg1"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 500 500",
    }}
    viewBox="0 0 500 500"
    {...props}
  >
    <path
      d="M0 382.398h500v.25H0zM359 391.922h90.901v.25H359zM306.333 389.208h24.887v.25h-24.887zM349.524 401.208h38.587v.25h-38.587zM52.459 399.528h22.638v.25H52.459zM84 399.528h26.889v.25H84zM178.309 395.306h46.838v.25h-46.838zM237.014 337.8H43.915a5.715 5.715 0 0 1-5.708-5.708V60.66a5.715 5.715 0 0 1 5.708-5.708h193.099a5.715 5.715 0 0 1 5.707 5.708v271.432a5.715 5.715 0 0 1-5.707 5.708zM43.915 55.203a5.464 5.464 0 0 0-5.458 5.458v271.432a5.464 5.464 0 0 0 5.458 5.458h193.099a5.463 5.463 0 0 0 5.457-5.458V60.66a5.464 5.464 0 0 0-5.457-5.458H43.915zM453.31 337.8H260.212a5.714 5.714 0 0 1-5.707-5.708V60.66a5.715 5.715 0 0 1 5.707-5.708H453.31a5.714 5.714 0 0 1 5.708 5.708v271.432a5.714 5.714 0 0 1-5.708 5.708zM260.212 55.203a5.463 5.463 0 0 0-5.457 5.458v271.432a5.463 5.463 0 0 0 5.457 5.458H453.31a5.464 5.464 0 0 0 5.458-5.458V60.66a5.464 5.464 0 0 0-5.458-5.458H260.212z"
      style={{
        fill: "#ebebeb",
      }}
    />
    <path
      d="M316.492 79.797h104.926v116.579H316.492z"
      style={{
        fill: "#e6e6e6",
      }}
      transform="rotate(-180 368.955 138.087)"
    />
    <path
      d="M294.04 79.797h122.538v116.579H294.04z"
      style={{
        fill: "#f5f5f5",
      }}
      transform="rotate(-180 355.31 138.087)"
    />
    <path
      d="M304.51 88.859h101.598v98.456H304.51z"
      style={{
        fill: "#fff",
      }}
      transform="rotate(90 355.31 138.087)"
    />
    <path
      d="M303.21 137.449h104.199v1.276H303.21z"
      style={{
        fill: "#f5f5f5",
      }}
      transform="rotate(90 355.31 138.087)"
    />
    <path
      d="M81.113 86.384h134.04v270.401H81.113z"
      style={{
        fill: "#e6e6e6",
      }}
      transform="rotate(-180 148.133 221.584)"
    />
    <path
      d="M71.801 86.384h140.878v270.401H71.801z"
      style={{
        fill: "#f0f0f0",
      }}
      transform="rotate(-180 142.24 221.584)"
    />
    <path
      d="M18.841 162.948h246.797v117.273H18.841z"
      style={{
        fill: "#fff",
      }}
      transform="rotate(90 142.24 221.584)"
    />
    <path
      d="m127.836 344.983 23.137-246.797h-26.705l-23.137 246.797zM148.529 344.983l23.137-246.797h-13.352l-23.138 246.797z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M265.857 276.507h25.051v105.892h-25.051z"
      style={{
        fill: "#e6e6e6",
      }}
      transform="rotate(-180 278.382 329.453)"
    />
    <path
      d="M280.431 382.399h-14.574v-14.618h29.854z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M414.667 276.507h25.051v105.892h-25.051z"
      style={{
        fill: "#e6e6e6",
      }}
      transform="rotate(-180 427.192 329.453)"
    />
    <path
      d="M265.857 276.507h161.529v100.861H265.857z"
      style={{
        fill: "#fafafa",
      }}
      transform="rotate(-180 346.621 326.937)"
    />
    <path
      d="M412.812 382.399h14.574v-14.618h-29.855z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M277.163 314.091H416.08v25.241H277.163z"
      style={{
        fill: "#f0f0f0",
      }}
      transform="rotate(-180 346.621 326.711)"
    />
    <path
      d="M277.163 344.727H416.08v25.241H277.163z"
      style={{
        fill: "#f0f0f0",
      }}
      transform="rotate(-180 346.621 357.347)"
    />
    <path
      d="M303.567 316.79h86.108a4.577 4.577 0 0 0 4.576-4.577v-.308H298.99v.308a4.578 4.578 0 0 0 4.577 4.577z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M277.163 283.455H416.08v25.241H277.163z"
      style={{
        fill: "#f0f0f0",
      }}
      transform="rotate(-180 346.621 296.076)"
    />
    <path
      d="M303.567 286.154h86.108a4.577 4.577 0 0 0 4.576-4.577v-.308H298.99v.308a4.578 4.578 0 0 0 4.577 4.577zM303.567 347.425h86.108a4.576 4.576 0 0 0 4.576-4.576v-.308H298.99v.308a4.578 4.578 0 0 0 4.577 4.576z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M292.331 228.92h4.938v45.823h-4.938z"
      style={{
        fill: "#f5f5f5",
      }}
      transform="rotate(-180 294.8 251.832)"
    />
    <path
      d="M292.967 228.882h1.395v45.823h-1.395z"
      style={{
        fill: "#fafafa",
      }}
      transform="rotate(-180 293.664 251.794)"
    />
    <path
      d="M283.915 276.507h21.77a2.743 2.743 0 0 0-2.743-2.743h-16.284a2.743 2.743 0 0 0-2.743 2.743zM285.335 261.439c.781 0 1.42-.639 1.42-1.42v-25.28c0-.781-.639-1.42-1.42-1.42-.781 0-1.42.639-1.42 1.42v25.28c0 .781.639 1.42 1.42 1.42z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M272.697 241.052h44.206l-1.577-4.358A14.694 14.694 0 0 0 301.509 227h-13.417a14.694 14.694 0 0 0-13.817 9.694l-1.578 4.358z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M405.397 228.92h4.938v45.823h-4.938z"
      style={{
        fill: "#f5f5f5",
      }}
      transform="rotate(-180 407.866 251.832)"
    />
    <path
      d="M406.033 228.882h1.395v45.823h-1.395z"
      style={{
        fill: "#fafafa",
      }}
      transform="rotate(-180 406.73 251.794)"
    />
    <path
      d="M396.981 276.507h21.77a2.743 2.743 0 0 0-2.743-2.743h-16.284a2.743 2.743 0 0 0-2.743 2.743zM398.401 261.439c.781 0 1.42-.639 1.42-1.42v-25.28c0-.781-.639-1.42-1.42-1.42-.781 0-1.42.639-1.42 1.42v25.28c0 .781.639 1.42 1.42 1.42z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M385.763 241.052h44.206l-1.577-4.358A14.694 14.694 0 0 0 414.575 227h-13.417a14.694 14.694 0 0 0-13.817 9.694l-1.578 4.358z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <ellipse
      cx={250}
      cy={416.238}
      rx={193.889}
      ry={11.323}
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="m166.527 113.939-.628-.778c4.404-3.552 9.015-6.9 13.705-9.952l.546.838a166.378 166.378 0 0 0-13.623 9.892zM159.146 120.253l-.672-.741a171.36 171.36 0 0 1 4.088-3.589l.647.762a169.042 169.042 0 0 0-4.063 3.568z"
      style={{
        fill: "#263238",
      }}
    />
    <ellipse
      cx={216.408}
      cy={181.205}
      rx={115.245}
      ry={81.186}
      style={{
        fill: "#263238",
      }}
      transform="rotate(-41.225 216.407 181.208)"
    />
    <ellipse
      cx={216.408}
      cy={181.205}
      rx={99.97}
      ry={70.426}
      style={{
        fill: "#fff",
      }}
      transform="rotate(-41.225 216.407 181.208)"
    />
    <path
      d="M237.152 103.893c-43.291 3.505-87.672 40.959-99.129 83.657l156.769-12.691c11.457-42.698-14.35-74.471-57.64-70.966z"
      style={{
        fill: "#407bff",
      }}
    />
    <path
      d="m138.023 187.55 78.385-6.346-40.758-50.18c-17.939 15.139-31.899 35.177-37.627 56.526z"
      style={{
        opacity: 0.7,
        fill: "#fff",
      }}
    />
    <path
      d="M237.152 103.893c-21.645 1.752-43.563 11.992-61.502 27.131l40.758 50.18 20.744-77.311z"
      style={{
        opacity: 0.4,
        fill: "#fff",
      }}
    />
    <path
      d="m237.152 103.893-20.744 77.312 70.094-59.155c-10.43-12.842-27.705-19.909-49.35-18.157z"
      style={{
        opacity: 0.1,
        fill: "#fff",
      }}
    />
    <ellipse
      cx={216.408}
      cy={181.204}
      rx={40.786}
      ry={28.732}
      style={{
        fill: "#fff",
      }}
      transform="rotate(-41.225 216.407 181.208)"
    />
    <ellipse
      cx={216.408}
      cy={181.205}
      rx={23.802}
      ry={16.768}
      style={{
        fill: "#263238",
      }}
      transform="rotate(-41.225 216.406 181.208)"
    />
    <path
      d="M275.076 139.854s-25.598 8.842-52.628 27.893l7.371 13.204c27.03-19.051 45.257-41.097 45.257-41.097z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M147.036 217.057c-1.031 0-1.714-.793-1.544-1.839.179-1.101 1.233-2.066 2.356-2.155l86.117-6.837c1.127-.087 1.888.731 1.71 1.832-.179 1.101-1.233 2.066-2.356 2.155l-86.117 6.837a1.89 1.89 0 0 1-.166.007zM221.545 225.259c-1.032 0-1.714-.792-1.544-1.839.179-1.101 1.233-2.066 2.356-2.155l28.034-2.226c1.126-.091 1.888.731 1.709 1.832-.178 1.101-1.233 2.066-2.356 2.155l-28.034 2.225a1.544 1.544 0 0 1-.165.008zM79.148 261.08c-1.031 0-1.714-.793-1.544-1.839.178-1.101 1.233-2.066 2.356-2.155l28.034-2.226c1.121-.091 1.888.731 1.709 1.832-.178 1.101-1.233 2.066-2.356 2.155l-28.034 2.226a1.852 1.852 0 0 1-.165.007zM104.091 244.71c-1.032 0-1.714-.793-1.544-1.839.179-1.101 1.233-2.066 2.356-2.155l83.993-6.669c1.123-.089 1.888.731 1.709 1.832-.179 1.101-1.233 2.066-2.356 2.155l-83.993 6.668a1.544 1.544 0 0 1-.165.008z"
      style={{
        fill: "#407bff",
      }}
    />
    <path
      d="m284.392 334.509 3.559 7.194 17.608-6.329-3.56-7.194zM198.766 393.639l5.354 5.987 13.602-15.446-6.59-4.583z"
      style={{
        fill: "#ff8b7b",
      }}
    />
    <path
      d="m205.339 399.028-3.638-9.084a.666.666 0 0 0-.755-.41l-7.104 1.452c-.737.151-1.16.978-.866 1.676 1.22 2.9 2.243 4.958 3.717 8.639.907 2.265 3.411 9.199 4.664 12.326 1.225 3.059 4.458 2.171 4.221.767-1.064-6.296-1.094-11.387-.205-13.954.16-.463.144-.966-.034-1.412zM288.759 341.241l-3.887-7.857a.7.7 0 0 0-.794-.369l-7.098 1.743a1.168 1.168 0 0 0-.768 1.668c1.45 2.81 3.643 6.823 5.405 10.386 2.061 4.165 2.342 4.295 4.759 9.179 1.461 2.953 4.853 2.296 4.512.926-1.554-6.237-2.424-7.318-1.832-13.665.065-.691.01-1.39-.297-2.011z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M357.969 205.238c-.368 2.599-.768 5.401-1.079 8.116-.306 2.738-.614 5.47-.844 8.199-.463 5.439-.77 10.904-.483 16.061.026.649.057 1.295.134 1.907l.179 1.781c.065.638.173.954.353 1.291.187.33.611.738 1.364 1.097.739.366 1.738.673 2.843.861 2.226.395 4.82.416 7.44.275a80.532 80.532 0 0 0 3.991-.344c1.321-.155 2.72-.372 3.964-.562l1.935 5.707c-1.456.693-2.798 1.222-4.236 1.738-1.42.51-2.865.984-4.354 1.358-1.486.388-3.01.711-4.589.929-1.578.224-3.213.368-4.936.32-1.721-.049-3.543-.236-5.479-.834-1.911-.6-4.009-1.671-5.738-3.45-1.751-1.756-2.858-4.126-3.319-6.272-.074-.304-.101-.478-.136-.672l-.104-.586-.206-1.174a30.688 30.688 0 0 1-.336-2.324c-.754-6.161-.682-12.088-.385-17.972.192-2.934.402-5.858.762-8.76.357-2.924.709-5.753 1.268-8.756l11.991 2.066z"
      style={{
        fill: "#ff8b7b",
      }}
    />
    <path
      d="M345.19 197.954c-5.259 5.588-3.14 19.697-3.14 19.697l14.777 2.39s3.434-8.048 2.143-16.251c-1.582-10.056-8.031-11.944-13.78-5.836z"
      style={{
        fill: "#407bff",
      }}
    />
    <path
      d="M350.025 204.189c-2.444-3.144-5.017-4.261-6.365-4.005-3.27 6.406-1.609 17.467-1.609 17.467l10.467 1.694c1.181-3.236 1.337-10.241-2.493-15.156z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m373.945 244.788 8.56-5.621 1.908 8.707s-5.332 5.392-9.773 1.591l-.695-4.677zM387.78 237.289l.992 7.524-4.359 3.06-1.908-8.707z"
      style={{
        fill: "#ff8b7b",
      }}
    />
    <path
      d="m305.553 335.375-9.075 3.263-3.561-7.197 9.075-3.264zM211.127 379.6l6.593 4.584-6.377 7.236-6.593-4.585z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M314.738 178.714c-6.077 31.104-22.268 59.18-24.533 65.374 5.806 2.432 27.694 11.554 39.627 16.525 22.248-33.116 25.656-57.075 26.169-62.873a2.39 2.39 0 0 0-.972-2.148 87.597 87.597 0 0 0-4.318-2.963 76.302 76.302 0 0 0-3.219-1.963 56.445 56.445 0 0 0-1.942-1.077c-4.284-2.261-11.687-5.152-16.849-7.022a92.287 92.287 0 0 0-3.28-1.106 113.121 113.121 0 0 0-10.683-2.747z"
      style={{
        fill: "#407bff",
      }}
    />
    <path
      d="m338.559 223.074 2.187.552c-1.525 1.459-3.235 2.472-5.128 3.039-2.169.652-4.421.683-6.755.094-2.259-.57-3.979-1.511-5.159-2.822-1.181-1.311-1.721-2.942-1.621-4.893.1-1.951.68-3.955 1.741-6.013a20.72 20.72 0 0 1 4.618-5.968c1.915-1.72 3.914-2.868 5.999-3.446 2.084-.578 4.143-.61 6.176-.097 1.725.435 3.055 1.216 3.991 2.343.936 1.126 1.384 2.505 1.345 4.135-.039 1.63-.502 3.305-1.388 5.025-1.056 2.05-2.548 3.767-4.476 5.152-2.425 1.75-4.692 2.359-6.8 1.826-.568-.143-.936-.368-1.103-.674-.167-.306-.183-.696-.047-1.17-1.28.713-2.447.936-3.501.67-1.136-.287-1.842-.985-2.117-2.095-.275-1.11-.019-2.428.768-3.956a14.47 14.47 0 0 1 4.021-4.831c2.073-1.621 3.965-2.215 5.676-1.783 1.218.308 1.837 1.081 1.855 2.322l.946-1.274 2.71.684-6.005 8.255c-.378.523-.608.864-.691 1.025-.104.201-.142.362-.115.482.027.12.095.194.205.222.328.083.873-.043 1.633-.378 1.013-.435 2-1.112 2.962-2.032a11.452 11.452 0 0 0 2.293-3.032c1.019-1.978 1.262-3.786.729-5.424-.533-1.638-1.891-2.733-4.075-3.284-1.855-.468-3.656-.421-5.404.142-1.748.563-3.364 1.568-4.848 3.014-1.484 1.447-2.676 3.043-3.574 4.787-.874 1.696-1.305 3.332-1.292 4.907.012 1.575.513 2.848 1.501 3.82.988.972 2.331 1.672 4.029 2.1 1.636.413 3.18.501 4.633.263 1.45-.237 2.811-.799 4.081-1.687zm-8.546-7.758c-.526 1.021-.737 1.817-.633 2.388.104.571.416.923.936 1.054.39.098.814.078 1.273-.06.343-.095.73-.284 1.161-.565.615-.399 1.297-1.035 2.045-1.909.748-.874 1.331-1.717 1.75-2.529.468-.908.648-1.651.539-2.228-.109-.577-.437-.935-.984-1.073-.589-.149-1.271-.019-2.046.39-.775.408-1.544 1.075-2.307 2-.762.924-1.34 1.769-1.734 2.532z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M338.294 164.45c-1.197 5.863-4.276 16.236-9.576 18.272 1.725 4.156 4.166 9.145 10.508 12.054 6.342 2.909 7.063-1.986 6.337-5.031-5.442-3.963-3.417-8.382-.608-12.155l-6.661-13.14z"
      style={{
        fill: "#ff8b7b",
      }}
    />
    <path
      d="m341.008 169.81 3.943 7.773c-.668.891-1.286 1.82-1.766 2.773-2.17-1.296-4.285-5.153-3.522-7.673.381-1.256.915-2.394 1.345-2.873z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M354.28 164.67c6.272.457 11.819-15.18 2.674-17.002-6.171-1.23-6.111 2.467-6.767 6.498s.118 10.214 4.093 10.504z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M339.013 156.75c-1.616 7.801-2.958 12.284-.154 17.276 4.218 7.509 14.644 6.571 18.558-.59 3.523-6.445 5.573-18.119-1.107-23.215-6.58-5.02-15.681-1.272-17.297 6.529z"
      style={{
        fill: "#ff8b7b",
      }}
    />
    <path
      d="M337.665 162.33c6.272.457 10.471-13.845 1.326-15.667-6.171-1.23-6.692 2.377-6.596 6.238.102 4.083 1.296 9.139 5.27 9.429z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M342.734 151.389c4.222 2.055 24.611 6.444 23.444-2.333-1.167-8.778-12.056-12.833-12.056-12.833s6.333 6.945 1.111 5.278c-5.222-1.667-6.735-2.889-13.006-4 0 0 6.165 5.111-.994 3.667-7.159-1.445-12.167 1.944-12.167 1.944s5.778 3.307 5.278 11.268c-.483 7.707 8.39-2.991 8.39-2.991z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M334.035 162.695c.293 1.975 1.43 3.616 2.711 4.553 1.927 1.41 3.678-.212 3.804-2.553.113-2.107-.754-5.381-3.006-5.859-2.219-.47-3.845 1.601-3.509 3.859z"
      style={{
        fill: "#ff8b7b",
      }}
    />
    <path
      d="m303.916 249.811-8.339 26.855 42.931 27c-7.412 12.361-45.057 24.661-45.057 24.661l6.022 12.184s52.425-4.032 63.058-27.179c10.229-22.26-32.699-52.721-32.699-52.721l-25.916-10.8z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m309.49 285.42-7.46-4.69 9.22-13.4s.23 10.74-1.76 18.09z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m309.49 285.42-7.46-4.69 9.22-13.4s.23 10.74-1.76 18.09z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M320.53 256.737s-34.615 57.579-48.907 76.258c-15.65 20.454-56.174 59.021-56.174 59.021l-12.07-8.393s34.971-38.579 48.189-59.931c15.056-24.321 38.637-79.604 38.637-79.604l30.325 12.649z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m216.713 393.281-14.214-9.883 2.953-4.246 16.08 10.771zM299.947 342.182l-7.183-14.516 4.69-2.321 8.276 16.105z"
      style={{
        fill: "#407bff",
      }}
    />
    <path
      d="M350.068 162.06c-.17.625.023 1.221.432 1.332.408.111.877-.305 1.047-.93.17-.625-.023-1.221-.432-1.332s-.877.305-1.047.93zM357.129 163.98c-.17.625.023 1.221.432 1.332.408.111.877-.305 1.047-.93.17-.625-.023-1.221-.432-1.332-.408-.111-.877.306-1.047.93z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M354.8 163.681s.44 3.991 1.531 6.155c-1.386.731-3.137-.252-3.137-.252l1.606-5.903z"
      style={{
        fill: "#ff5652",
      }}
    />
    <path
      d="M350.106 170.539c-2.289-.967-2.943-2.916-2.972-3.007a.19.19 0 0 1 .122-.239.19.19 0 0 1 .239.122c.007.023.775 2.289 3.509 3.032a.19.19 0 1 1-.1.366 6.268 6.268 0 0 1-.798-.274zM348.511 159.187a.38.38 0 0 1-.084-.651c1.665-1.286 3.329-.726 3.399-.702a.38.38 0 0 1-.248.718c-.055-.018-1.357-.441-2.685.585a.38.38 0 0 1-.382.05zM360.832 162.379a.382.382 0 0 1-.226-.28c-.312-1.65-1.565-2.199-1.618-2.221a.38.38 0 0 1 .292-.702c.069.031 1.684.716 2.074 2.783a.38.38 0 0 1-.522.42z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M205.046 402.067c-.276-.689-.433-1.496-.518-2.068a.2.2 0 0 1 .088-.196.182.182 0 0 1 .207.002c.276.197 2.691 1.949 2.604 2.959-.013.157-.088.375-.37.523-.378.199-.736.215-1.064.047-.413-.212-.72-.7-.947-1.267zm-.069-1.666c.257 1.377.692 2.33 1.192 2.586.219.112.457.098.726-.043.132-.069.154-.145.159-.203.045-.525-1.13-1.612-2.077-2.34z"
      style={{
        fill: "#407bff",
      }}
    />
    <path
      d="M204.54 400.043a.2.2 0 0 1 .117-.26c.092-.033 2.278-.804 3.321-.251.271.144.448.363.524.652.118.445-.094.671-.23.766-.754.524-2.957-.246-3.677-.832a.183.183 0 0 1-.055-.075zm3.573.17a.648.648 0 0 0-.315-.337c-.668-.355-1.996-.04-2.649.151.879.51 2.473.915 2.918.605.041-.028.136-.095.072-.336a.671.671 0 0 0-.026-.083zM288.115 342.441a.19.19 0 0 1 .301-.177c.331.243 3.228 2.41 3.173 3.525a.606.606 0 0 1-.314.506c-.327.194-.659.224-.987.091-1.089-.443-1.826-2.645-2.168-3.919a.294.294 0 0 1-.005-.026zm3.092 3.253c-.081-.635-1.454-1.908-2.563-2.779.522 1.719 1.177 2.869 1.788 3.118.215.087.428.066.65-.066.086-.051.124-.11.129-.197a.668.668 0 0 0-.004-.076z"
      style={{
        fill: "#407bff",
      }}
    />
    <path
      d="M288.115 342.441a.19.19 0 0 1 .121-.202c.086-.033 2.12-.798 3.324-.254.367.166.623.435.76.8.184.492.014.745-.162.87-.748.534-3.156-.417-3.975-1.092a.189.189 0 0 1-.068-.122zm3.9.669a.857.857 0 0 0-.051-.191 1.007 1.007 0 0 0-.56-.587c-.791-.357-2.077-.04-2.688.148.971.623 2.772 1.187 3.221.867.028-.021.099-.071.078-.237zm.127-.258.001.005-.001-.005z"
      style={{
        fill: "#407bff",
      }}
    />
    <path
      d="m315.047 192.323-3.153-2.11c-1.089-.715-2.2-1.407-3.316-2.09a144.495 144.495 0 0 0-6.769-3.919c-2.284-1.232-4.587-2.398-6.916-3.41-1.164-.53-2.334-.967-3.5-1.449l-1.756-.62-.44-.155-.22-.078c-.058-.02-.016-.007-.028-.015.009-.018-.072-.037-.165-.064-.456-.087-1.461-.039-2.506.143-1.065.19-2.222.487-3.397.857-2.363.724-4.8 1.681-7.231 2.713-4.847 2.093-9.793 4.474-14.587 6.905l-3.397-4.978c4.377-3.688 8.891-7.007 13.837-9.984 2.473-1.484 5.031-2.882 7.828-4.105a37.895 37.895 0 0 1 4.486-1.626c1.632-.449 3.379-.858 5.682-.785.592.033 1.199.086 1.879.23l.474.105.275.07.551.14 2.204.571c1.433.435 2.883.861 4.265 1.391a77.487 77.487 0 0 1 8.13 3.439 96.536 96.536 0 0 1 7.669 4.143c1.234.759 2.461 1.529 3.674 2.329a62.279 62.279 0 0 1 3.663 2.572l-7.236 9.78z"
      style={{
        fill: "#ff8b7b",
      }}
    />
    <path
      d="M326.977 183.833c-1.77-4.383-18.5-10.375-18.5-10.375l-5.404 14.124s5.848 3.863 10.698 6.768c9.268 5.554 16.687-1.896 13.206-10.517z"
      style={{
        fill: "#407bff",
      }}
    />
    <path
      d="m262.705 186.929-3.392 7.354-5.17-6.699s3.892-6.568 6.68-4.002l1.882 3.347z"
      style={{
        fill: "#ff8b7b",
      }}
    />
    <path
      d="m252.155 196.588-2.617-5.878 4.605-3.126 5.17 6.699z"
      style={{
        fill: "#ff8b7b",
      }}
    />
  </svg>
)
export default Svg1

import React from 'react'

export default function Footer() {
  return (
    <div className='footer'>
      <div>
       <span>SVGs : <a href='www.freepik.com'> freepik.com </a></span>
       </div>
       <div>
        <span>
          Copyright <a href='/'>Shopitt </a>  2023
        </span>
       </div>
    </div>
  )
}
